import React from 'react';

import './Community.scss';

import Discord from 'images/showcase/discord.svg';
import Github from 'images/showcase/github.svg';
import Reddit from 'images/showcase/reddit.svg';
import Twitter from 'images/showcase/twitter.svg';
import Youtube from 'images/showcase/youtube.svg';

function Community({ links, community }) {
  return (
    <section className="community">
      <div className="container container--narrow">
        <p className="community__title section-title">
          {community.reach_community_title_text}
        </p>
        <p className="community__text">
          {community.reach_community_subtitle_text}
        </p>
        <div className="community__links">
          <a
            href={links.discord}
            className="community__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Discord} alt="discord" />
          </a>
          <a
            href={links.github}
            className="community__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Github} alt="github" />
          </a>
          <a
            href={links.reddit}
            className="community__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Reddit} alt="reddit" />
          </a>
          <a
            href={links.youtube}
            className="community__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Youtube} alt="youtube" />
          </a>
          <a
            href={links.twitter}
            className="community__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Twitter} alt="twitter" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default React.memo(Community);
