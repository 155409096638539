import React, { useState } from 'react';
import ContactFormActive from 'components/ContactFormActive';
import ContactFormSent from 'components/ContactFormSent';

function ContactForm({contactUs}) {
  const {
    sent_text,
    thank_you_for_writing_us_text,
    close_button,
    ...contactActive
  } = contactUs
  const contactSent = {sent_text, thank_you_for_writing_us_text, close_button}

  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const toggleForm = () => {
    setIsSubmitted(!isSubmitted);
  };

  return (
    <>
      {!isSubmitted
        ? <ContactFormActive toggleForm={toggleForm} contactActive={contactActive} />
        : <ContactFormSent toggleForm={toggleForm} contactSent={contactSent} />
      }
    </>
  );
}

export default React.memo(ContactForm);
