import React from 'react';
import ContactForm from 'components/ContactForm';

import './Contact.scss';

function Contact({ background, page, contactUs }) {
  const { contact_us_title_text, contact_us_subtitle_text, ...rest } = contactUs;

  const sectionClass = `contact ${page && 'contact--' + page} ${background && 'contact--' + background}`;

  return (
    <section className={sectionClass}>
      <div className="container container--narrow">
        <p className="contact__title section-title">
          {contact_us_title_text}
        </p>
        <p className="contact__subtitle">
          {contact_us_subtitle_text}
        </p>
        <ContactForm contactUs={rest} />
      </div>
    </section>
  );
}

export default React.memo(Contact);
