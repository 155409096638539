import React from 'react';

import MessageSentImage from 'images/contact/message-sent.svg';

import './ContactFormSent.scss';

function ContactFormSent({ toggleForm, contactSent }) {
  return (
    <div className="contact__form-sent">
      <div className="contact__form-sent-text">
        <img
          src={MessageSentImage}
          alt="form is sent"
          className="contact__form-sent-image"
        />
        <p className="contact__form-sent-title">
          {contactSent.sent_text}
        </p>
        <p className="contact__form-sent-subtitle">
          {contactSent.thank_you_for_writing_us_text}
        </p>
      </div>
      <div
        className="contact__form-sent-button button button--red"
        onClick={toggleForm}
      >
        {contactSent.close_button}
      </div>
    </div>
  );
}

export default React.memo(ContactFormSent);
