import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import Contact from 'components/Contact';
import Community from 'components/Community';

export default function ContactPage({ pageContext: { contactUs, contact, links, navigation, tag } }) {
  return (
    <>
    <Helmet>
      <meta property='og:image' content='/seo/preview.jpg' />
      <meta property='og:image:width' content={820} />
      <meta property='og:image:height' content={540} />
    </Helmet>
    <Layout
      mainBackground='dark'
      page='contact'
      links={links}
      navigation={navigation}
      tag={tag}
      metaTitle='Contact | Reach Blockchain Development Platform'
      metaDescription='Contact our experienced team of developers and blockchain experts to learn about the Reach platform and find decentralized solutions for your business needs.'
    >
      <Contact page='contact' contactUs={contactUs} />
      <Community links={links} community={contact} />
    </Layout>
    </>
  );
}
