import React, { useState } from 'react';
import { Link } from 'gatsby';

import './ContactFormActive.scss';

function ContactFormActive({ toggleForm, contactActive }) {
  const [ email, setEmail ] = useState('');
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ country, setCountry ] = useState('');
  const [ comment, setComment ] = useState('');
  const [ isFirstNameValid, setIsFirstNameValid ] = useState(true);
  const [ isLastNameValid, setIsLastNameValid ] = useState(true);
  const [ isCountryValid, setIsCountryValid ] = useState(true);
  const [ isCommentValid, setIsCommentValid ] = useState(true);

  const submitForm = (event) => {
    event.preventDefault();

    let isValidated = true;

    const firstName = event.target.elements.first_name.value;
    const lastName = event.target.elements.last_name.value;
    const country = event.target.elements.country.value;
    const comment = event.target.elements.comment.value;

    if (firstName.length > 60) {
      isValidated = false;
      setIsFirstNameValid(false);
    }

    if (lastName.length > 60) {
      isValidated = false;
      setIsLastNameValid(false);
    }

    if (country.length > 60) {
      isValidated = false;
      setIsCountryValid(false);
    }

    if (comment.length > 500) {
      isValidated = false;
      setIsCommentValid(false);
    }

    if (isValidated) {
      const formData = {};

      [...event.target.elements]
        .filter(element => element.type !== 'submit')
        .forEach(element => formData[element.name] = element.value);

      fetch(contactActive.url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => toggleForm());
    }
  };

  return (
    <form
      action="#"
      className="contact__form"
      onSubmit={submitForm}
    >
      <div className="contact__form-left">
        <div className="contact__form-field">
          <label htmlFor="email" className="contact__form-label">
            {contactActive.email_label}
          </label>
          <input
            type="email"
            placeholder={contactActive.email_placeholder}
            id="email"
            name="email"
            className="contact__form-input"
            required={true}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="contact__form-field">
          <label htmlFor="first-name" className="contact__form-label">
            {contactActive.first_name_label}
          </label>
          <input
            type="text"
            placeholder={contactActive.first_name_placeholder}
            id="first-name"
            name="first_name"
            className={isFirstNameValid ? 'contact__form-input' : 'contact__form-input contact__form-input--invalid'}
            required={true}
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
              setIsFirstNameValid(true);
            }}
          />
        </div>
        <div className="contact__form-field">
          <label htmlFor="last-name" className="contact__form-label">
            {contactActive.last_name_label}
          </label>
          <input
            type="text"
            placeholder={contactActive.last_name_placeholder}
            id="last-name"
            name="last_name"
            className={isLastNameValid ? 'contact__form-input' : 'contact__form-input contact__form-input--invalid'}
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
              setIsLastNameValid(true);
            }}
          />
        </div>
      </div>
      <div className="contact__form-right">
        <div className="contact__form-field">
          <label htmlFor="country" className="contact__form-label">
            {contactActive.country_label}
          </label>
          <input
            type="text"
            placeholder={contactActive.country_placeholder}
            id="country"
            name="country"
            className={isCountryValid ? 'contact__form-input' : 'contact__form-input contact__form-input--invalid'}
            value={country}
            onChange={(event) => {
              setCountry(event.target.value);
              setIsCountryValid(true);
            }}
          />
        </div>
        <div className="contact__form-field">
          <label htmlFor="comment" className="contact__form-label">
            {contactActive.comment_label}
          </label>
          <textarea
            name="comment"
            id="comment"
            placeholder={contactActive.comment_placeholder}
            className={isCommentValid ? 'contact__form-textarea' : 'contact__form-textarea contact__form-textarea--invalid'}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
              setIsCommentValid(true);
            }}
          >
          </textarea>
        </div>
      </div>
      <div className="contact__form-field contact__form-field--submit">
        <p className="contact__form-text">
          <span dangerouslySetInnerHTML={{__html: contactActive.by_clicking_submit_below_text}}/>
          <Link
            to={`/${contactActive.tag}/privacy`}
            className="contact__form-link"
          >
            {contactActive.personal_data_and_privacy_policy_link}
          </Link>
          .
        </p>
        <div className="contact__form-submit-wrapper">
          <button
            type="submit"
            id="button"
            className="contact__form-submit button button--red"
          >
            {contactActive.submit_button}
          </button>
        </div>
      </div>
    </form>
  );
}

export default React.memo(ContactFormActive);
